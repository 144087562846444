import queryClient from 'api/queryClient'
import { FunctionComponent, useEffect } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Routes, Route } from 'react-router-dom'
import { Login, Logout } from './modules/Auth'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import AppLayout from 'components/AppLayout'
import { useAuth, useUser, usePendo } from '@matillion/hub-client'
import Instances from 'modules/Instances'
import Activity from 'modules/Activity'
import { Toaster } from '@matillion/component-library'
import PipelineRunDetails from 'modules/PipelineRunDetails'
import Lineage from 'modules/Lineage/Lineage'
import DatasetDetailView from 'modules/DatasetDetailView/DatasetDetailView'
import './locales/i18n'
import config from 'config'

const App: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()
  const { user, profile, organisation } = useUser()
  const pendo = usePendo()

  useEffect(() => {
    if (isLoggedIn) pendo.initialize(user.sub, organisation.id)
  }, [isLoggedIn, pendo, user, organisation])

  useEffect(() => {
    if (isLoggedIn) {
      pendo.updateOptions(
        {
          email: user.email,
          full_name: profile.name
        },
        {
          name: organisation.name,
          subdomain: organisation.subdomain,
          region: organisation.region
        }
      )
    }
  }, [isLoggedIn, user, profile, organisation, pendo])

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <AppLayout>
      <Toaster.Provider align="right" position="top" theme="light">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route path="/instances" element={<Instances />} />
            <Route
              path="/pipeline/:pipelineId"
              element={<PipelineRunDetails />}
            />
            <Route path="/lineage" element={<Lineage />} />
            <Route
              path="/lineage/dataset/:datasetId"
              element={<DatasetDetailView />}
            />
            <Route path="*" element={<Activity />} />
          </Routes>
          {config.enableReactQueryDevtools && (
            <section aria-label="React Query Devtools">
              <ReactQueryDevtools />
            </section>
          )}
        </QueryClientProvider>
      </Toaster.Provider>
    </AppLayout>
  )
}

export default App
