import { Client, Message, Frame, Stomp, StompHeaders } from '@stomp/stompjs'
import config from 'config'
import { StepProgressUpdate } from 'types/eos'

type MessageHandler = (message: StepProgressUpdate) => void
type CloseHandler = (event: CloseEvent) => void

export function createStompConnection(
  pipelineExecutionId: string,
  accountId: string,
  jwt: string,
  onMessage: MessageHandler,
  onClose?: CloseHandler
): Client {
  const client = Stomp.client(config.websocketUrl)

  const subscribeHeader: StompHeaders = {
    accountId: accountId
  }

  const onConnectCallback = function () {
    client.subscribe(
      '/topic/pipeline-execution-updates/' + pipelineExecutionId,
      (message: Message) => {
        const stepProgressUpdate = JSON.parse(
          message.body
        ) as StepProgressUpdate
        onMessage(stepProgressUpdate)
      },
      subscribeHeader
    )
  }

  const onErrorCallback = function (frame: Frame) {
    console.error('Error connecting to stomp:', frame)
  }

  const connectHeaders: StompHeaders = {
    Authorization: `Bearer ${jwt}`,
    accountId: accountId
  }

  client.connect(connectHeaders, onConnectCallback, onErrorCallback)

  client.onWebSocketClose = (event: CloseEvent) => {
    if (onClose) {
      onClose(event)
    }
  }

  client.onWebSocketError = (event: Event) => {
    console.error('Error connecting to stomp:', event)
  }

  client.activate()

  return client
}
